import { CTA } from '@finn/ui-components';
import { FooterData } from '@finn/ui-cosmic';

import { useIsPlpPage } from './useIsPlpPage';

type Props = {
  data: FooterData;
};

const FooterBlurb = ({ data }: Props) => {
  const isPlp = useIsPlpPage();
  const blurbLine1 = data.metadata?.footer_blurb_line_1;
  const blurbLine2 = data.metadata?.footer_blurb_line_2;
  const footerCTA = data.metadata?.footer_cta;

  if (isPlp || !(blurbLine1 || blurbLine2)) return null;

  return (
    <div className="py-16">
      <h2 className="mobile-t3-semibold md:web-t3-semibold pb-8">
        {blurbLine1}
        {blurbLine2 && (
          <span className="mobile-t3-light md:web-t3-light">{blurbLine2}</span>
        )}
      </h2>
      {footerCTA?.metadata && <CTA data={footerCTA.metadata} />}
    </div>
  );
};
export default FooterBlurb;
